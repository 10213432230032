

.bg-control{

}

.container-control{
    display: flex !important;
    flex-direction: column !important;
}
.MuiPaper-root{
    background-color: transparent !important;
    box-shadow: none !important;
}
.coming-soon-text{
    font-size: 60px;
    font-family: "Copper-Bold";
    margin-left: auto;
    margin-top: auto;
    margin-right: auto;
    color: white;
    text-shadow: 0px 0px 20px #ffffff;
}

.main-control{
    width: 100% !important;
    height: auto;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    
}

.coming-soon-control{
    width: 100% !important;
    height: 500px;
    display: flex;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
}

.bg-border-control{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    background-color: #3d1010;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    position: relative;
    padding: 46px;
    height: auto;
    border-radius: 46px;
    box-shadow: 0px 0px 10px #c55f5f;
    
}

.logo-hotel{
    width: 300px;
    height: auto;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
}

.gif-control{
    width: 300px;
    height: auto;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    object-fit: contain;
}

.grid-control{
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 50px;

    
}

.item-control{
    display: flex;
    flex-direction: column;
}

.text-public-sale{
    font-size: 24px;
    font-family: "Copper-Bold";
    color: white;
    text-shadow: 0px 0px 20px #ffffff;
}

.text-price{
    margin-top: 24px;
    font-size: 20px;
    font-family: "Copper-Bold";
    color: white;
}

.button-mint{
    cursor: pointer !important;
    width: auto;
    height: 110px;
    object-fit: contain;
}

@media only screen and (max-width: 1440px) {
    .main-control{
        width: auto;
    }

}

@media only screen and (max-width: 1200px) {
    .main-control{
        width: auto;
    }

}

@media only screen and (max-width: 960px) {
    .main-control{
        width: auto;

    }
    .gif-control{
        width: 300px;
    }
    .bg-border-control{
        width: 60%;
      
    }
}

@media only screen and (max-width: 660px) {
    .main-control{
        width: auto;

    }
    .gif-control{
        width: 200px;
    }
    .bg-border-control{
        width: 60%;
      
    }

}

@media only screen and (max-width: 480px) {
    .main-control{
        width: auto;
        
    }

    .logo-hotel{
        width: 230px;
    }
    .text-public-sale{
        font-size: 18px;
        
    }

    .gif-control{
        width: 200px;
    }
    .text-price{
        font-size: 18px;
    }

    .button-mint{
        height: 70px;
    }

    .bg-border-control{
        width: 100% !important;
        margin-left: 0px;
        margin-right: 0px;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 25px;
        padding-bottom: 25px;
    }

}