/* Customize your main colors in :root variables */
:root {
  --main-background-color: #343A50;
  --card-background-color: #804980;
  --countdown-background-color: #433765;
  --main-text-color:#F7F6F4;
  --title-text-color:#3CBA8B;
}

@font-face {
  font-family: "Copper-Bold";
  src: local("Copper-Bold"), url(../src/fonts/CopperplateCC-Bold.ttf) format("truetype");
}

body{
/*  background-color:var(--main-background-color);*/
  background: url("./assets/images/bg-mint.jpg");
  background-size: cover;
  font-family: 'Copper-Bold';
  font-size: 1.1em;
}

h2, p{
  color: var(--main-text-color);
}

h3{
font-weight: normal;
}



